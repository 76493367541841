import React from "react";
import { useLocation } from "react-router-dom";
import PageLayout from "../layout/PageLayout";
import ErrorMessage from "../components/ErrorMessage";

const ErrorPage = () => {
  const location = useLocation();
  const { message } = location.state || {};

  return (
    <PageLayout>
      <ErrorMessage message={message} />
    </PageLayout>
  );
};

export default ErrorPage;