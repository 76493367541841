import React from "react";
import Modal from "./Modal";

const TermsModal = ({ onClose, src }) => (
  <Modal onClose={onClose}>
    <iframe
      src={src}
      title="Terms and Conditions"
      className="iframe-terms"
    />
  </Modal>
);

export default TermsModal;