import React from "react";
import PageLayout from "../layout/PageLayout";

const ThankYouPage = () => {
  return (
    <PageLayout type="meta">
        <h2>Thank You for subscribing to Meta!</h2>
        <p>We’re thrilled to have you on board.</p>
        <ul>
          <li>
            Your payment and invoice will be processed automatically on the{" "}
            <strong>10th of each month</strong>.
          </li>
          <li>
            Need to cancel? Simply email us at <a href="mailto:support@mozrest.com">support@mozrest.com</a>, and we’ll take care of it.
          </li>
        </ul>
        <p>
          Enjoy your subscription, and feel free to reach out if you have any questions or need assistance!
        </p>
    </PageLayout>
  );
};

export default ThankYouPage;