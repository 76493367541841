import React from "react";

const CommercialConditions = ({ conditions, type }) => {
  if (type === "meta") {
    return (
      <>
        <h2>Commercial Conditions & Fees</h2>
        <p>
          Increase your bookings by receiving online reservations directly from
          your restaurant page on the <strong>Meta</strong> apps and website.
        </p>
        <ul>
          <li>
            <strong>
              {conditions.fullPrice / 100} {conditions.currency}
            </strong>{" "}
            per month excluding VAT.
          </li>
          <li>
            Payment and Invoice will be processed on the 10th of the month.
          </li>
        </ul>
      </>
    );
  }

  if (type === "smartchannel") {
    return (
      <>
        <h2>Commercial Conditions & Fees</h2>
        <p>
          Increase your visibility and reach by receiving reservation from
          multiple booking channels.
        </p>
        <p>
          Smart Channel Manager including a reservation widget for your website,
          Reserve with Google, Facebook & Instagram and Listing & Review.
        </p>
        <ul>
          <li>
            <strong>
              {conditions.fullPrice / 100} {conditions.currency}
            </strong>{" "}
            per month excluding VAT.
          </li>
        </ul>
        <p>
          Your Booking Channel(s) charged per confirmed cover (no charge for
          no-show or cancellation), price ex VAT:
        </p>
        <ul>
          <li>
            MICHELIN Guide{" "}
            <strong>2 EUR</strong>
          </li>
          <li>
            TripAdvisor{" "}
            <strong>2 EUR</strong>
          </li>
          <li>
            Mozrest Audience{" "}
            <strong>2 EUR</strong>
          </li>
        </ul>
        <p>
        Payment and invoice processed on the <strong>10th of the month</strong> for the subscription of the current month and the covers of the previous month.
        </p>
      </>
    );
  }

  return null;
};

export default CommercialConditions;
