import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initializePaddle } from "../utils/paddleUtils";
import PageLayout from "../layout/PageLayout";
import "../styles.css";

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, opportunityId, venueId } = location.state || {};

  useEffect(() => {
    if (!userData || !opportunityId || !venueId) {
      console.error("Missing required data for payment.");
      navigate("/");
      return;
    }

    if (document.querySelector(".checkout-container")) {
      initializePaddle({
        vendorId: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID, 10),
        environment: process.env.REACT_APP_PADDLE_ENVIRONMENT || "production",
        productId: parseInt(process.env.REACT_APP_PADDLE_PRODUCT_ID_META, 10),
        targetClassName: "checkout-container",
        userData,
        opportunityId,
        venueId,
        navigate,
      });
    } else {
      console.error("Checkout container not found.");
    }
  }, [navigate, userData, opportunityId, venueId]);

  return (
    <PageLayout type="meta">
        <h2>Complete Your Payment</h2>
        <p>
          Please provide your payment details to complete the subscription.
        </p>
        <div className="checkout-container"></div>
    </PageLayout>
  );
};

export default PaymentPage;
