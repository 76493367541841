export const initializePaddle = ({
    vendorId,
    environment,
    productId,
    targetClassName,
    userData,
    opportunityId,
    venueId,
    navigate,
  }) => {
    try {
      const Paddle = window.Paddle;
  
      // Ensure the Paddle script is loaded
      if (!Paddle) {
        console.error("Paddle script not loaded.");
        return;
      }
  
      // Set the Paddle environment
      if (environment === "sandbox") {
        Paddle.Environment.set("sandbox");
      }
  
      // Setup Paddle with the vendor ID
      Paddle.Setup({ vendor: vendorId });
  
      // Open the Paddle inline checkout
      Paddle.Checkout.open({
        method: "inline",
        product: productId,
        allowQuantity: false,
        disableLogout: true,
        frameTarget: targetClassName,
        frameInitialHeight: 500,
        frameStyle: "width:100%; min-width:312px; background-color: transparent; border: none;",
        email: userData.email || "customer@example.com",
        country: userData?.country || "GB",
        postcode: userData.company?.postalCode || "HA99PX",
        passthrough: JSON.stringify({
          companyId: userData.company?.id,
          userId: userData.id,
          opportunityId,
          venueId,
        }),
        successCallback: (data) => {
          console.log("Checkout successful:", data);
          navigate("/thank-you");
        },
        closeCallback: () => console.log("Checkout closed."),
        loadCallback: () => console.log("Checkout loaded."),
      });
    } catch (error) {
      console.error("Error initializing Paddle:", error);
    }
  };