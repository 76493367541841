import React from "react";
import PageLayout from "../layout/PageLayout";

const DefaultPage = () => {
  return (
    <PageLayout>
      <p>
        To see the available subscription types, please check them on our
        website <a href="https://mozrest.com/en-gb/products/">https://mozrest.com</a>.
      </p>
    </PageLayout>
  );
};

export default DefaultPage;
